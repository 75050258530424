import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, forkJoin } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import {
  InterconnectionCurtailmentHistoryModel,
  InterconnectionGenerationModel,
  InterconnectionModel,
  InterconnectionNotification,
} from 'src/app/shared/models/interconnection';
import { HttpErrorResponse, HttpParams, HttpStatusCode } from '@angular/common/http';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { LoggingService } from 'src/app/shared/services/logging.service';
import { Router } from '@angular/router';
import { SiteNotification } from 'src/app/shared/models/sites/siteNotification';
import { BatteryData } from 'src/app/shared/models/battery/batteryData';

export interface CurtailmentChartModel {
  reliability_curtailment: number;
  economic_curtailment: number;
  month: Date | string;
  uncertain_curtailment: number;
}

@Injectable({
  providedIn: 'root',
})
export class SitesService extends ApiService {
  // Loading Subjects
  public isAllSitesLoading$ = new BehaviorSubject(false);
  public isSiteBatteryLoading$ = new BehaviorSubject(false);
  public isSiteModelLoading$ = new BehaviorSubject(false);
  public isSiteGenerationLoading$ = new BehaviorSubject(false);
  public isSiteNotificationsLoading$ = new BehaviorSubject(false);
  public isSiteCurtailmentHistoryLoading$ = new BehaviorSubject(false);

  public allInterconnections$: BehaviorSubject<InterconnectionModel[]> = new BehaviorSubject<
    InterconnectionModel[]
  >([]);

  public siteModel$: BehaviorSubject<InterconnectionModel> =
    new BehaviorSubject<InterconnectionModel>(null);

  public specificSite$: BehaviorSubject<number> = new BehaviorSubject(null);

  private _logger = new LoggingService('SitesService');

  constructor(private router: Router) {
    super();
  }

  getAllUserSites(): Observable<InterconnectionModel[]> {
    this.isAllSitesLoading$.next(true);
    return this.http.get<InterconnectionModel[]>(`${this.apiUrl}/external/sites/`).pipe(
      map((interconns: InterconnectionModel[]) => {
        interconns.sort((a, b) => a.unique_id - b.unique_id);
        interconns.map((i) => {
          i.unique_id_display = `${i.unique_id}`.padStart(6, '0');
        });
        this.allInterconnections$.next(interconns);
      }),
      catchError((err) => this.handleError('Unable to load all sites', err)),
      finalize(() => this.isAllSitesLoading$.next(false))
    ) as Observable<InterconnectionModel[] | null>;
  }

  getCurtailmentDetails(uniqueId: string, year: string): Observable<CurtailmentChartModel[]> {
    return this.http
      .get<CurtailmentChartModel[]>(
        `${this.apiUrl}/sites/solar/${uniqueId}/curtailment-history/monthly-aggregated?year=${year}`
      )
      .pipe(
        map((data) => data),
        catchError((err) => this.handleError('Unable to load all sites', err)),
        finalize(() => this.isAllSitesLoading$.next(false))
      );
  }

  // /sites/solar/<unique_id>/
  getSiteModel(uniqueId: string): Observable<InterconnectionModel | null> {
    this.isSiteModelLoading$.next(true);
    const uri = `${this.apiUrl}/sites/solar/${uniqueId}/`;
    this._logger.info(`getSiteModel(${uniqueId}) for uri ${uri}`);
    return this.http.get<InterconnectionModel | null>(uri).pipe(
      tap((siteModel) => {
        this._logger.debug(`Got site model response ${JSON.stringify(siteModel)}`);
        this.siteModel$.next(siteModel);
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === HttpStatusCode.NotFound) {
          this._logger.error('getSiteModel', `Site with Unique ID ${uniqueId} not found`);
          void this.router.navigateByUrl('/site-information/sites');
          this.snackBar.error(`Site with Unique ID ${uniqueId} not found`);
          return of(null);
        }
        this._logger.error('getSiteModel', `Unable to load site with Unique ID ${uniqueId}`);
        void this.router.navigateByUrl('/site-information/sites', { replaceUrl: true });
        return this.handleError(`Unable to load site with Unique ID ${uniqueId}`, err);
      }),
      finalize(() => this.isSiteModelLoading$.next(false))
    );
  }

  // /sites/solar/<unique_id>/generation/
  getSiteGeneration(
    uniqueId: number,
    startDate: Date,
    endDate: Date
  ): Observable<{
    data: InterconnectionGenerationModel[];
    live: number;
    recloser_status: string;
    comm_status: string;
    breaker_status: string;
  }> {
    this.isSiteGenerationLoading$.next(true);
    const params = ApiService.setStartEndDateParams(startDate, endDate);
    const uri = `${this.apiUrl}/sites/solar/${uniqueId}/generation/`;
    this._logger.info(
      `getSiteGeneration(${uniqueId}) for uri ${uri} and params ${params?.toString()}`
    );
    return this.http.get<any>(uri, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        this._logger.error(
          'getSiteModel',
          `Unable to load generation data with Unique ID ${uniqueId}`
        );
        return this.handleError(`Unable to load generation data`, err);
      }),
      finalize(() => this.isSiteGenerationLoading$.next(false))
    );
  }

  getBatteryData(uniqueId: number, startDate: Date, endDate: Date): Observable<BatteryData[]> {
    this.isSiteBatteryLoading$.next(true);
    const params = ApiService.setStartEndDateParams(startDate, endDate);
    const uri = `${this.apiUrl}/sites/battery/${uniqueId}/generation`;
    return this.http.get<any[]>(uri, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        console.log('BATTERY ERROR');
        this._logger.error(
          'getBatteryData',
          `Unable to load battery data with Unique ID ${uniqueId}`
        );
        return this.handleError(`Unable to load battery data`, err);
      }),
      map((resp) => resp.map((x) => new BatteryData(x))),
      finalize(() => {
        console.log('BATTERY NOT LOADING');
        this.isSiteBatteryLoading$.next(false);
      })
    );
  }

  // /sites/solar/<unique_id>/notifications/
  getSiteNotifications(
    uniqueId: number,
    startDate: Date = null,
    endDate: Date = null
  ): Observable<SiteNotification[]> {
    this.isSiteNotificationsLoading$.next(true);

    let params = new HttpParams();
    if (startDate && endDate) {
      params = ApiService.setStartEndDateParams(startDate, endDate);
    }

    const uri = `${this.apiUrl}/sites/solar/${uniqueId}/notifications/`;
    this._logger.info(
      `getSiteNotifications(${uniqueId}) for uri ${uri} and params ${params?.toString()}`
    );
    return this.http.get<InterconnectionNotification[]>(uri, { params }).pipe(
      map((resp) => resp.map((notification) => new SiteNotification(notification))),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(`Unable to get site notifications`, err);
      }),
      finalize(() => this.isSiteNotificationsLoading$.next(false))
    );
  }

  // /sites/solar/<unique_id>/curtailment-history/
  getSiteCurtailmentHistory(
    uniqueId: number,
    startDate: Date,
    endDate: Date
  ): Observable<InterconnectionCurtailmentHistoryModel[]> {
    this.isSiteCurtailmentHistoryLoading$.next(true);
    const uri = `${this.apiUrl}/sites/solar/${uniqueId}/curtailment-history/`;
    const params = ApiService.setStartEndDateParams(startDate, endDate);
    return this.http.get<InterconnectionCurtailmentHistoryModel[]>(uri, { params }).pipe(
      catchError((err: HttpErrorResponse) => {
        this._logger.error(
          'getSiteModel',
          `Unable to load curtailment history for unique id ${uniqueId}`
        );
        return this.handleError(`Unable to load curtailment history`, err);
      }),
      finalize(() => this.isSiteCurtailmentHistoryLoading$.next(false))
    );
  }

  getSiteSettlements(id: number) {
    // These are the slithering little snake types that come in
    interface GenerationPaymentSnake {
      invoice_number: string;
      payment_amount: string;
      payment_date: string;
      payment_method: string;
      payment_ref: string;
    }

    interface ContractPaymentSnake {
      payment_amount: string;
      payment_due_date: string;
      payment_for: string;
      payment_invoice_number: string;
      payment_name: string;
      payment_received_date: string;
      payment_status: string;
      payment_tracking_team: string;
      payment_type: string;
      updated_date: Date;
    }

    // This is the much better camel case it gets turned into before moving along
    const conversionKeys = {
      invoice_number: 'invoiceNumber',
      payment_amount: 'paymentAmount',
      payment_date: 'paymentDate',
      payment_due_date: 'paymentDueDate',
      payment_for: 'paymentFor',
      payment_invoice_number: 'paymentInvoiceNumber',
      payment_method: 'paymentMethod',
      payment_name: 'paymentName',
      payment_received_date: 'paymentReceivedDate',
      payment_ref: 'paymentRef',
      payment_status: 'paymentStatus',
      payment_tracking_team: 'paymentTrackingTeam',
      payment_type: 'paymentType',
      status: 'status',
      updated_date: 'updatedDate',
    };

    // This is the conversion function that takes it from gross snake case to awesome camel case.
    // Camels are so much cooler than snakes. This returns [GenerationPayment[], ToDukePayment[]],
    // but because of the way the return value is crafted, Typescript doesn't like that value.
    const settlementConversion = (data: [GenerationPaymentSnake[], ContractPaymentSnake[]]) =>
      data.map((dat: GenerationPaymentSnake[] | ContractPaymentSnake[]) =>
        dat.map((d: GenerationPaymentSnake | ContractPaymentSnake) => {
          const ret = {};
          Object.keys(d).forEach((key: string) => {
            if (key == 'payment_amount') {
              // Formats payment amount to $XX,YYY.ZZ
              ret[conversionKeys[key]] = new Intl.NumberFormat('en-us', {
                style: 'currency',
                currency: 'USD',
              }).format(parseFloat(d[key]));
            } else {
              ret[conversionKeys[key]] = d[key];
            }
          });
          return ret;
        })
      );

    return forkJoin<[GenerationPaymentSnake[], ContractPaymentSnake[]]>([
      this.http.get<GenerationPaymentSnake[]>(`${this.apiUrl}/accounts/${id}/generation-payments/`),
      this.http.get<ContractPaymentSnake[]>(`${this.apiUrl}/accounts/${id}/contract-payments/`),
    ]).pipe(map(settlementConversion));
  }
}
