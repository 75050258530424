<div *ngIf="showBattery; else siteDetails">
  <app-battery-header [batteryData]="batteryDetails" [site]="site"></app-battery-header>
</div>
<ng-template #siteDetails>
  <ng-container *ngIf="site">
    <div>
      <section id="site-header" class="gap-20 mb-20">
        <div class="flex justify-center mb-20">
          <div class="flex-1"></div>

          <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="center"
            class="text-lg"
            (selectedTabChange)="selectTab($event)"
            *ngIf="
              interconnection.battery_type !== EnergyType.Battery ||
              interconnection.energy_source !== EnergyType.Microgrid
            "
          >
            <mat-tab label="Home"></mat-tab>
            <mat-tab
              *ngIf="interconnection?.user_role === userLevelRequest.Owner && showPaymentInfo"
              label="Payments"
            ></mat-tab>
            <mat-tab label="Messages ({{ siteNotifications?.length || 0 }})"></mat-tab>
          </mat-tab-group>

          <div class="flex-1"></div>
        </div>
        <app-site-curtailment-site-header
          [site]="site"
          [solarSiteWrite]="solarSiteWrite"
          (getUpdatedSite)="getUpdatedInfo($event)"
        ></app-site-curtailment-site-header>
      </section>

      <div *ngIf="selectedTab === 'Home'">
        <div *ngIf="interconnection.battery_type !== EnergyType.Battery && showOutageTables">
          <section id="pending-outages">
            <app-outages-pending [site]="site"></app-outages-pending>
          </section>
        </div>
        <div *ngIf="interconnection.battery_type !== EnergyType.Battery && showOutageTables">
          <section id="planned-outages">
            <app-site-planned-outages [site]="site" [outages]="outages" [showEdit]="true"></app-site-planned-outages>
          </section>
        </div>
        <section
          id="battery-storage"
          class="mb-40"
          *ngIf="interconnection.battery_type && interconnection.battery_type !== EnergyType.Solar"
        >
          <app-battery-storage-chart [batteryData]="batteryData" (dateSelection)="setBatteryData($event)">
          </app-battery-storage-chart>
        </section>

        <section
          id="battery-load"
          class="mb-40"
          *ngIf="interconnection.battery_type && interconnection.battery_type !== EnergyType.Solar"
        >
          <app-load-history-chart
            [batteryData]="batteryData"
            [selectedDate]="batterySelectedDate"
          ></app-load-history-chart>
        </section>

        <app-loading *ngIf="siteService.isSiteModelLoading$ | async"></app-loading>
      </div>

      <div
        class="generation-section"
        *ngIf="interconnection.battery_type !== EnergyType.Battery && selectedTab === 'Home'"
      >
        <section id="generation">
          <app-site-generation-chart
            [uniqueId]="interconnection.unique_id"
            [generationData]="generationData"
            (dateSelection)="setGenerationData($event)"
          ></app-site-generation-chart>
        </section>
      </div>

      <div
        *ngIf="
          selectedTab === 'Home' && interconnection.battery_type && interconnection.battery_type !== EnergyType.Solar
        "
        class="mb-20"
      >
        <section>
          <app-battery-header [batteryData]="batteryData"></app-battery-header>
        </section>
      </div>

      <div *ngIf="selectedTab === 'Home' && interconnection.battery_type !== EnergyType.Battery" class="flex gap-20">
        <section
          *ngIf="interconnection?.user_role === userLevelRequest.Owner"
          class="p-20 w-1/3 grid grid-flow-row auto-rows-max"
        >
          <div class="text-xl mb-10">Power Purchase Agreement</div>

          <div class="border-t-1 border-gray-light mt-4 pt-10 mb-6">
            <div class="grid grid-flow-row auto-rows-max gap-10">
              <div class="flex flex-row justify-between">
                <span class="font-bold"> Account # </span>

                <span>{{ site?.contract.billingAcctNum || "--" }}</span>
              </div>
              <div class="flex flex-row justify-between">
                <span class="font-bold"> Contract Type </span>

                <span>{{ site?.contract.negotiatedType || "--" }}</span>
              </div>

              <div class="flex flex-row justify-between">
                <span class="font-bold"> Amendments </span>

                <span>{{ site?.contract.amendments.length || "0" }}</span>
              </div>
            </div>
          </div>
          <div class="border-t-1 border-gray-light mt-4 pt-10">
            <div class="grid grid-flow-row auto-rows-max gap-10">
              <div *ngIf="site.contract.contractStartDate" class="flex flex-row justify-between">
                <span class="font-bold">PPA Start Date</span>

                <span>{{ site.contract.contractStartDate | date : "shortDate" }}</span>
              </div>

              <div *ngIf="site.contract.negotiatedType === 'Negotiated'" class="flex flex-row justify-between">
                <span class="font-bold">Commercial Operation Date</span>

                <span *ngIf="site.commercialOperationalDate">{{
                  site.commercialOperationalDate | date : "shortDate"
                }}</span>
              </div>

              <div *ngIf="site.contract.negotiatedType === 'Standard'" class="flex flex-row justify-between">
                <span class="font-bold">Operational Date - IDD</span>

                <span>{{ site.contract.operationalDate | date : "shortDate" }}</span>
              </div>

              <div class="flex flex-row justify-between">
                <span class="font-bold">Contract Expiration</span>

                <span>{{ (site.contract.contractEndDate | date : "shortDate") || "Unknown" }}</span>
              </div>

              <div class="flex flex-row justify-between">
                <span class="font-bold">Contract Length</span>

                <span>{{ site.contract.lengthOfContract }}</span>
              </div>

              <div *ngIf="site.contract.timeRemaining; else endsThisMonth" class="flex flex-row justify-between">
                <span class="font-bold"> Time Remaining </span>

                <span>{{ site.contract.timeRemaining }}</span>
              </div>

              <ng-template #endsThisMonth>
                <div class="flex flex-row justify-between">
                  <strong> Expires </strong>

                  <span> This Month </span>
                </div>
              </ng-template>
            </div>
          </div>
        </section>
        <section [ngClass]="{ 'w-full': interconnection?.user_role !== userLevelRequest.Owner }" class="p-20 w-2/3">
          <app-generation-violations
            [siteNotifications]="siteNotifications"
            [site]="site"
            (monthSelectionEvent)="newMonthSelection($event)"
          >
          </app-generation-violations>
        </section>
      </div>

      <div *ngIf="selectedTab === 'Home' && showCurtailmentInfo">
        <div class="w-full flex gap-20">
          <section class="w-1/3">
            <app-curtailments-summary [site]="site"></app-curtailments-summary>
          </section>
          <section class="w-2/3 !pb-[10px]">
            <app-curtailment-chart></app-curtailment-chart>
          </section>
        </div>

        <div class="w-full flex gap-20 justify-center">
          <app-remove-site-dialog [role]="interconnection?.user_role" [site]="site"></app-remove-site-dialog>
        </div>
      </div>
    </div>

    <div *ngIf="selectedTab === 'Payments'">
      <div *ngFor="let data of tableDatas | keyvalue : tableOrder">
        <section>
          <app-payment-history [site]="site" [tableType]="data.key" [data]="data.value"></app-payment-history>
        </section>
      </div>
    </div>

    <div *ngIf="selectedTab === 'Messages'">
      <app-site-notifications [siteNotifications]="siteNotifications"></app-site-notifications>
    </div>

    <app-confirmation
      [modalOpen]="refreshPageModalOpen"
      [description]="'Auto-refresh has ended, please click here to extend your session.'"
      cancelText=""
      [confirmText]="'Refresh Page'"
      (closeModal)="closeRefreshPageModal()"
    ></app-confirmation>
  </ng-container>
</ng-template>
