<button class="btn bg-red border-red hover:bg-red caution-button" (click)="toggleDialog()"
        *ngIf="role === userLevelRequest.Owner || role === userLevelRequest.Operator || userLevelRequest.Admin">Remove Site</button>


<app-de-modal
  [showHeader]="false"
  [showMask]="true"
  [open]="openSiteDialog"
  [showClose]="true"
  [showCloseOnly]="true"
  (close)="toggleDialog()"
>
    <div position="centered" class="confirmation flex flex-col items-center" [hidden]="confirmation">
      <img class="safety-icon mb-16" matPrefix src="assets/icons/safety-base.svg"
           alt="safety" />
      <div class="mb-16 text-center max-w-sm">
        <div class="modal-header mb-16">Remove Site</div>
        <div class="modal-text mb-16">
          Removing {{ site.name }} will only remove access to view this site and its data. Access can be restored by completing the ‘Register New Site’ process again.
        </div>
        <div class="modal-text mb-16">
          Please indicate if you want to remove access for yourself or your entire organization.
        </div>
      </div>
      <form [formGroup]="removeSiteForm"  class="mb-16">
      <mat-radio-group formControlName="removeSiteOption">
        <!-- First option - special case -->
        <div>
          <mat-radio-button value=False>
            Remove Access for myself
          </mat-radio-button>
        </div>
        <div *ngIf="role === userLevelRequest.Owner && showRemoveAccessRadio">
          <mat-radio-button value=True>
            Remove Access for my entire organization
          </mat-radio-button>
        </div>
      </mat-radio-group>
      </form>
      <div class="confirmation__buttons">
        <button class="caution-button caution-button--secondary" (click)="toggleDialog()">Cancel</button>
        <button class="caution-button" (click)="toggleConfirm()">Continue</button>
      </div>
    </div>
  <div position="centered" class="confirmation flex flex-col items-center" [hidden]="!confirmation">
    <div class="modal-header mb-16">Confirm Site Removal</div>
    <div class="modal-text mb-16">I confirm I will lose access to {{ site.name }} for {{ this.removeSiteForm.controls.removeSiteOption.value ? "myself" : "my entire organization" }}.</div>
    <div class="reconfirmation__buttons  mb-16">
      <button class="caution-button caution-button--secondary"  (click)="toggleConfirm()">Cancel</button>
      <button class="caution-button" (click)="removeSiteDialog()">Confirm Remove</button>
    </div>
  </div>
</app-de-modal>
